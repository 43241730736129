import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import { callApi } from 'api';
import { CallItemStatistics, FacilityTransferStatistics, Graphic } from 'api/call.api';
import Chart from 'chart.js/auto';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import React, { FC, useCallback, useEffect, useState } from 'react';
import ChartComponent from 'react-chartjs-2';

Chart.register(TreemapController, TreemapElement);
interface ChartDataProps<DATASET> {
  labels?: string[];
  datasets: DATASET[];
}

interface ChartDatasetProps {
  label?: string;
  data: number[];
  backgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
}
interface TreemapDatasetProps {
  label?: string;
  tree: any[];
  backgroundColor?: string[];
  borderColor?: string[];
  borderWidth?: number;
  spacing?: number;
  groups?: string[];
  key?: string;
}

type StandardChartDataProps = ChartDataProps<ChartDatasetProps>;
type TreemapChartDataProps = ChartDataProps<TreemapDatasetProps>;

// Un tableau qui présente les hôpitaux de provenance des transferts et les hôpitaux de destination des transferts.
// - En ligne les établissement, colonne : Nom de l'établissement, Type (Origine / Destination), Nombre de transferts concernés

export const StatisticsFiguresPage: FC<{ dateStart: any; dateEnd: any }> = ({
  dateStart,
  dateEnd,
}) => {
  const classes = useStyles();

  const [dataGraph1, setDataGraph1] = useState<StandardChartDataProps | undefined>();
  const [dataGraph2, setDataGraph2] = useState<TreemapChartDataProps | undefined>();
  const [dataGraph3, setDataGraph3] = useState<TreemapChartDataProps | undefined>(); // urgences régulés chez les femmes
  const [dataGraph4, setDataGraph4] = useState<TreemapChartDataProps | undefined>(); // urgences régulés chez les hommes
  const [dataGraph5, setDataGraph5] = useState<TreemapChartDataProps | undefined>(); // motifs des appels chez les enfants de 0-15ans
  const [dataGraph6, setDataGraph6] = useState<StandardChartDataProps | undefined>(); // causes des traumatismes
  const [dataGraph7, setDataGraph7] = useState<FacilityTransferStatistics[]>([]); // causes des traumatismes

  const handleSubmit = useCallback((dateStart?: any, dateEnd?: any) => {
    const dataGraph1: StandardChartDataProps = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.TYPES_APPUI,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details).forEach(([key, value]: [string, number]) => {
          dataGraph1.labels?.push(key);
          dataGraph1.datasets[0].data.push(value);
        });
        setDataGraph1(dataGraph1);
      });

    const dataGraph2: TreemapChartDataProps = {
      labels: [],
      datasets: [
        {
          tree: [],
          groups: ['key'],
          key: 'value',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: ['white'],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.MOTIFS_REGULES,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details).forEach(([key, value]: [string, number]) => {
          dataGraph2.labels?.push(key);
          // dataGraph2.datasets[0].label = 'Motif Régulés';
          // dataGraph2.datasets[0].groups?.push(key);
          dataGraph2.datasets[0].tree.push({ key, value });
        });
        setDataGraph2(dataGraph2);
      });
    const dataGraph3: TreemapChartDataProps = {
      labels: [],
      datasets: [
        {
          tree: [],
          groups: ['key'],
          key: 'value',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: ['white'],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.MOTIFS_REGULES_FEMMES,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details).forEach(([key, value]: [string, number]) => {
          dataGraph3.datasets[0].tree.push({ key, value });
        });
        setDataGraph3(dataGraph3);
      });
    const dataGraph4: TreemapChartDataProps = {
      labels: [],
      datasets: [
        {
          tree: [],
          groups: ['key'],
          key: 'value',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: ['white'],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.MOTIFS_REGULES_HOMMES,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details).forEach(([key, value]: [string, number]) => {
          dataGraph4.datasets[0].tree.push({ key, value });
        });
        setDataGraph4(dataGraph4);
      });

    const dataGraph5: TreemapChartDataProps = {
      labels: [],
      datasets: [
        {
          tree: [],
          groups: ['key'],
          key: 'value',
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: ['white'],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.MOTIFS_REGULES_ENFANTS_0_15,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details).forEach(([key, value]: [string, number]) => {
          dataGraph5.datasets[0].tree.push({ key, value });
        });
        setDataGraph5(dataGraph5);
      });

    const dataGraph6: StandardChartDataProps = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    callApi
      .getCallStatistics({
        graphic: Graphic.CAUSES_TRAUMA,
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: CallItemStatistics) => {
        Object.entries(stats.details)
          .sort(([sa, na], [sb, nb]) => (nb > na ? 1 : -1))
          .forEach(([key, value]: [string, number]) => {
            dataGraph6.labels?.push(key);
            dataGraph6.datasets[0].data.push(value);
          });
        setDataGraph6(dataGraph6);
      });

    // Graphique 7
    callApi
      .getFacilityTranferStatistics({
        userCreationDateStart: dateStart !== null ? dateStart : undefined,
        userCreationDateEnd: dateEnd !== null ? dateEnd : undefined,
      })
      .then((stats: FacilityTransferStatistics[]) => {
        setDataGraph7(stats);
      });
  }, []);

  useEffect(() => {
    handleSubmit(dateStart, dateEnd);
  }, [dateStart, dateEnd, handleSubmit]);

  const handleClickPrint = () => {
    window.print();
  };

  return (
    <>
      <Grid container spacing={4} justify="center" className={classes.contentContainer}>
        <Grid item xs={12} className={classes.hideOnPrint}>
          <PrintButton onClick={handleClickPrint} dateStart={dateStart} dateEnd={dateEnd} />
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="doughnut"
              data={dataGraph1}
              options={{
                cutout: 20,
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Répartition du type d’appui fourni par le CAN',
                  },
                  legend: {
                    display: true,
                    position: 'right',
                    align: 'center',
                    labels: {
                      textAlign: 'left',
                    },
                    title: {
                      display: true,
                      padding: 0,
                      color: Chart.defaults.color,
                      font: { weight: 'bold' },
                      text: 'Diagnostics',
                    },
                  },
                  labels: {
                    // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                    render: 'full',
                    fontSize: 12,
                    fontColor: 'rgba(44, 62, 80,1.0)',
                    fontStyle: 'bold',
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="treemap"
              data={dataGraph2}
              options={{
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Motifs des appels d’urgence régulés',
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="treemap"
              data={dataGraph3}
              options={{
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Motifs des appels d’urgence régulés chez les femmes',
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="treemap"
              data={dataGraph4}
              options={{
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Motifs des appels d’urgence régulés chez les hommes',
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="treemap"
              data={dataGraph5}
              options={{
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Motifs des appels d’urgence chez les enfants de 0-15 ans',
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>

        <Grid item xs={10} className={classes.printContainer}>
          <Paper className={classes.chartContainer}>
            <ChartComponent
              type="bar"
              data={dataGraph6}
              options={{
                indexAxis: 'y',
                plugins: {
                  title: {
                    display: true,
                    font: { weight: 'bold', size: 20 },
                    text: 'Causes des traumatismes régulés',
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              className={classes.chart}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} className={classes.printContainer}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} size="medium" stickyHeader>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">Nom de l'établissement</TableCell>
                  <TableCell align="left">Transfert(s) en provenance</TableCell>
                  <TableCell align="left">Transfert(s) à destination</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataGraph7.map(({ facility, totalDestination, totalOrigine }) => {
                  if (totalDestination || totalOrigine)
                    return (
                      <TableRow key={facility.Id}>
                        <TableCell align="left">{facility.name}</TableCell>
                        <TableCell align="left">{totalOrigine}</TableCell>
                        <TableCell align="left">{totalDestination}</TableCell>
                      </TableRow>
                    );
                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

const PrintButton: FC<{ onClick: any; dateStart: any; dateEnd: any }> = ({
  onClick,
  dateStart,
  dateEnd,
}) => {
  const [isDisabled, setDisabled] = useState(true);
  useEffect(() => {
    //disabled button on each date change and on first display
    setDisabled(true);
    setTimeout(() => setDisabled(false), 5000);
  }, [dateStart, dateEnd]);
  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
    >
      Imprimer
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      '@media print': {
        display: 'block',
      },
    },
    hideOnPrint: {
      '@media print': {
        display: 'none',
      },
    },
    printContainer: {
      '@media print': {
        pageBreakInside: 'avoid',
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
    chartContainer: {
      padding: 20,
      '@media print': {
        border: 'none',
      },
    },
    chart: {
      maxHeight: 400,
      '@media print': {
        maxWidth: '100%',
        maxHeight: 280,
      },
    },
    table: {
      minWidth: 900,
      backgroundColor: theme.palette.background.paper,
    },
    tableContainer: {
      maxHeight: 600,
      backgroundColor: 'transparent',
      '@media print': {
        maxHeight: 'none',
      },
    },
    tableRow: {
      height: theme.spacing(8),
    },
  })
);

export default StatisticsFiguresPage;
